import React from 'react'
import classnames from 'classnames'
import { Link } from 'gatsby'
import { Link as ScrollLink } from 'react-scroll'

import {
  main,
  secondary as secondaryCss,
  hollow as hollowCss,
  white as whiteCss,
  sending as sendingCss,
  success as successCss,
} from './Button.module.scss'

const Button = ({
  link,
  href,
  scrollTo,
  secondary,
  hollow,
  white,
  sending,
  success,
  className,
  children,
  ...props
}) => {
  const cssClass = classnames(
    main,
    secondary && secondaryCss,
    hollow && hollowCss,
    white && whiteCss,
    sending && sendingCss,
    success && successCss,
    className
  )

  if (link) {
    return (
      <Link to={link} className={cssClass} {...props}>
        <span>{children}</span>
      </Link>
    )
  } else if (scrollTo) {
    return (
      <ScrollLink to={scrollTo} className={cssClass} {...props}>
        <span>{children}</span>
      </ScrollLink>
    )
  } else if (link) {
    return (
      <a href={href} className={cssClass} {...props}>
        {children}
      </a>
    )
  } else {
    return (
      <button className={cssClass} {...props}>
        <span>{children}</span>
      </button>
    )
  }
}

export default Button
