import { createGlobalState } from 'react-hooks-global-state'

const { useGlobalState, setGlobalState } = createGlobalState({
  breakpoint: 'small',
  allSizes: {
    small: 0,
    medium: 640,
    large: 1024,
    xlarge: 1200,
  },
  routePath: '/',
  showGetStart: false,
})

export { useGlobalState, setGlobalState }
