import React from 'react'
import classnames from 'classnames'

import Linkedin from '../../images/linkedin.inline.svg'
import Facebook from '../../images/facebook.inline.svg'
import Twitter from '../../images/twitter.inline.svg'

import { list } from './SocialList.module.scss'

const SocialList = ({ className, networks, ...props }) => {
  const socialNetworks = [
    { url: networks.linkedin, icon: <Linkedin /> },
    { url: networks.facebook, icon: <Facebook /> },
    { url: networks.twitter, icon: <Twitter /> },
  ]

  return (
    <ul className={classnames(list, className)} {...props}>
      {socialNetworks.map((network, idx) => {
        if (network.url) {
          return (
            <li key={idx}>
              <a href={network.url} target={'_blank'} rel={'noreferrer'}>
                {network.icon}
              </a>
            </li>
          )
        } else {
          return false
        }
      })}
    </ul>
  )
}

export default SocialList
