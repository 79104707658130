// extracted by mini-css-extract-plugin
export var hollow = "Button-module--hollow--MjS8d";
export var lineLink = "Button-module--line-link--mmMPX";
export var lineLink_black = "Button-module--line-link_black--YABLW";
export var lineLink_grey = "Button-module--line-link_grey--AOl-u";
export var lineLink_primary = "Button-module--line-link_primary--Ti9+T";
export var lineLink_secondary = "Button-module--line-link_secondary--t9g4j";
export var lineLink_white = "Button-module--line-link_white--SZdMl";
export var main = "Button-module--main--6pD5J";
export var secondary = "Button-module--secondary--m8ggv";
export var sending = "Button-module--sending--iAmoL";
export var success = "Button-module--success--KE4f+";
export var white = "Button-module--white--mBHt3";