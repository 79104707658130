import React from 'react'
import Modal from 'react-modal'
import { useGlobalState, setGlobalState } from '../../utils/globalState'
import { CSSTransition } from 'react-transition-group'

import CloseIcon from '../../images/close.inline.svg'
import {
  overlay,
  portal,
  content,
  header,
  closeButton,
} from './PopUp.module.scss'

const PopUp = ({ name, children, title, ...props }) => {
  const [isOpen] = useGlobalState(name)

  const modalStyle = {
    overlay: {
      backgroundColor: null,
    },
    content: {
      top: null,
      left: null,
      right: null,
      bottom: null,
      border: null,
      background: null,
      borderRadius: null,
      padding: null,
      position: null,
      overflow: null,
    },
  }

  const handleCloseModal = () => {
    setGlobalState(name, false)
  }

  return (
    <CSSTransition in={isOpen} timeout={300} classNames={'dialog'}>
      <Modal
        isOpen={isOpen}
        style={modalStyle}
        onRequestClose={handleCloseModal}
        ariaHideApp={false}
        closeTimeoutMS={500}
        overlayClassName={overlay}
        portalClassName={portal}
        className={content}
        {...props}
      >
        <h4 className={header}>{title}</h4>
        {children}
        <button className={closeButton} onClick={handleCloseModal}>
          <CloseIcon width={36} height={36} />
        </button>
      </Modal>
    </CSSTransition>
  )
}

export default PopUp
