import React, { useState } from 'react'
import classnames from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import axios from 'axios'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'

import Button from '../Button/Button'

import { button } from './ContactForm.module.scss'

const ContactForm = ({ ...props }) => {
  const [isHuman, setIsHuman] = useState(false)
  const [formSending, setFormSending] = useState(false)
  const [success, setSuccess] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: 'onBlur',
  })

  const onSubmit = (data) => {
    console.log(data, process.env.GET_FORM)

    if (isHuman) {
      setFormSending(true)

      axios
        .post(process.env.GET_FORM, { ...data })
        .then((response) => {
          console.log(response)
          setFormSending(false)
          setSuccess(true)
          reset()
        })
        .catch((error) => {
          setFormSending(false)
          setSuccess(false)
          console.error(error)
        })
    } else {
      console.log('Bot')
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...props} noValidate>
      <div className={classnames('input', errors.name && 'not-valid')}>
        <input
          type="text"
          placeholder={'Name*'}
          {...register('name', {
            minLength: {
              value: 3,
              message: 'Minimum 3 letters',
            },
            maxLength: {
              value: 24,
              message: 'Maximum 24 letters',
            },
            pattern: {
              value: /[a-zA-Z(')]+$/gm,
              message: 'Allowed only letters',
            },
            required: 'Name is required',
          })}
        />
        {errors?.name && (
          <span className={'input__error'}>{errors.name.message}</span>
        )}
      </div>
      <div className={classnames('input', errors.email && 'not-valid')}>
        <input
          type="email"
          placeholder={'Email*'}
          {...register('email', {
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address',
            },
            required: 'Email is required',
          })}
        />
        {errors?.email && (
          <span className={'input__error'}>{errors.email.message}</span>
        )}
      </div>
      <div className={classnames('input', errors.phone && 'not-valid')}>
        <Controller
          name={'phone'}
          control={control}
          rules={{
            required: 'Phone is required',
            validate: (value) =>
              isValidPhoneNumber(value) || 'Invalid phone number',
            message: 'Invalid',
          }}
          render={({ field: { onChange, value } }) => (
            <PhoneInput
              value={value}
              onChange={onChange}
              defaultCountry={'AU'}
              international
            />
          )}
        />
        {errors?.phone && (
          <span className={'input__error'}>{errors.phone.message}</span>
        )}
      </div>
      <div className={'input'}>
        <textarea
          rows={4}
          placeholder={'Message...'}
          {...register('message')}
        />
      </div>
      <Button
        type={'submit'}
        className={button}
        secondary
        sending={formSending}
        disabled={formSending || success}
        success={success}
      >
        {formSending ? 'Sending...' : success ? 'Sended' : 'Submit'}
      </Button>
      <GoogleReCaptcha
        onVerify={() => {
          setIsHuman(true)
        }}
      />
    </form>
  )
}

export default ContactForm
