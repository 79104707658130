import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import classnames from 'classnames'

import RichText from '../RichText/RichText'
import PopUp from '../PopUp/PopUp'
import ContactForm from '../ContactForm/ContactForm'

import Logo from '../../images/logo.inline.svg'
import {
  main,
  container,
  containerBg,
  copyrights,
  info,
  infoDesc,
  infoEmail,
  navigation,
  navigationTitle,
  navigationMenu,
} from './Footer.module.scss'
import SocialList from '../SocialList/SocialList'

const Footer = ({ className, ...props }) => {
  const data = useStaticQuery(graphql`
    {
      wp {
        glentOptions {
          options {
            description
            address
            email
            social {
              linkedin
              facebook
              twitter
            }
          }
        }
        generalSettings {
          title
        }
        menus {
          nodes {
            locations
            name
            menuItems {
              nodes {
                label
                path
                parentId
              }
            }
          }
        }
      }
    }
  `)

  const getMenu = (allMenus, location) => {
    let res = false

    allMenus.forEach((menu) => {
      if (menu.locations.includes(location)) {
        res = menu
      }
    })

    return res
  }

  const currentYear = new Date().getFullYear()
  const year = currentYear > 2022 ? `2022-${currentYear}` : 2022
  const { description, address, email, social } = data.wp.glentOptions.options

  const menus = data.wp.menus.nodes
  const menu1 = getMenu(menus, 'FOOTER_1')
  const menu2 = getMenu(menus, 'FOOTER_2')
  const siteTitle = data.wp.generalSettings.title

  const Menu = ({ name, menuItems, className, ...props }) => {
    return (
      <div className={classnames(navigation, className)}>
        <h4 className={navigationTitle}>{name}</h4>
        <ul className={navigationMenu} {...props}>
          {menuItems.nodes.map((item, idx) => {
            return (
              <li key={idx}>
                <Link to={item.path}>{item.label}</Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <>
      <footer className={classnames(main, className)} {...props}>
        <div className={classnames('container', container)}>
          <div className={info}>
            <div>
              <Logo />
            </div>
            {description && (
              <RichText className={infoDesc}>{description}</RichText>
            )}
            {address && <RichText>{address}</RichText>}
            {email && (
              <div>
                <a
                  href={`mailto:${email}`}
                  className={classnames(
                    infoEmail,
                    'lined-link lined-link_white'
                  )}
                >
                  {email}
                </a>
              </div>
            )}
            {social && <SocialList networks={social} className={'white'} />}
          </div>
          <div>
            <Menu {...menu1} />
          </div>
          <div>
            <Menu {...menu2} />
          </div>
          <StaticImage
            src={'../../images/footer-bg.png'}
            alt={'background'}
            width={520}
            height={400}
            className={containerBg}
          />
        </div>
        <div className={copyrights}>
          © {siteTitle} {year}. All rights reserved.
        </div>
      </footer>
      <PopUp name={'showGetStart'} title={'Get started'}>
        <ContactForm />
      </PopUp>
    </>
  )
}

export default Footer
