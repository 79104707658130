import React from 'react'
import Layout from './src/components/Layout/Layout'
import { setGlobalState } from '/src/utils/globalState'

export const onRouteUpdate = ({ location }) => {
  setGlobalState('routePath', location.pathname)
}

export const wrapPageElement = ({ element, location, ...props }) => {
  return (
    <Layout location={location} {...props}>
      {element}
    </Layout>
  )
}
