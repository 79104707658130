import React, { useEffect, useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import classnames from 'classnames'
import { CSSTransition } from 'react-transition-group'

import menuSort from '../../utils/menuSort'
import { setGlobalState } from '../../utils/globalState'

import Breakpoint from '../Breakpoint/Breakpoint'
import Button from '../Button/Button'
import Logo from '../../images/logo.inline.svg'
import DropDown from '../../images/drop-down.inline.svg'

import {
  main,
  container,
  logo,
  menu as menuCss,
  subMenu as subMenuCss,
  menuItem,
  menuLink,
  subMenuLink,
  subMenuOverlay,
  menuButton,
  offCanvas,
  offCanvasButton,
  mobMenu,
  mobSubMenu,
} from './Header.module.scss'

const Header = ({ ...props }) => {
  const [showOffCanvas, setShowOffCanvas] = useState(false)

  const toggleOffCanvas = () => {
    setShowOffCanvas(!showOffCanvas)
  }

  const closeOffCanvas = () => {
    setShowOffCanvas(false)
  }

  const data = useStaticQuery(graphql`
    {
      wp {
        menus(where: { location: PRIMARY }) {
          nodes {
            menuItems {
              nodes {
                label
                path
                id
                parentId
              }
            }
          }
        }
      }
    }
  `)

  const menu = menuSort(data.wp.menus.nodes[0].menuItems.nodes)

  const [offset, setOffset] = useState(window.pageYOffset)

  useEffect(() => {
    setOffset(window.pageYOffset)
  }, [])

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset)
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const Menu = ({ items, className, ...props }) => {
    const SubMenu = ({
      label,
      path,
      subMenu,
      parentId,
      className,
      ...props
    }) => {
      const [open, setOpen] = useState(false)

      const toggleSubmenu = (ev) => {
        ev.preventDefault()
        setOpen(!open)
      }

      const keyPressHandler = (ev) => {
        const key = ev.nativeEvent.code
        const keys = ['Space', 'Enter']

        if (keys.includes(key)) {
          ev.preventDefault()
          setOpen(!open)
        }
      }

      const closeSubmenu = () => {
        setOpen(false)
      }

      return (
        <>
          <a
            href={path}
            tabIndex={0}
            className={classnames(menuLink, open && 'is-open')}
            onClick={toggleSubmenu}
            onKeyDown={keyPressHandler}
          >
            {label}
            <DropDown />
          </a>
          <CSSTransition in={open} timeout={200}>
            <ul className={classnames(subMenuCss, className)}>
              {subMenu.map((subMenuItem) => {
                return (
                  <li key={subMenuItem.id}>
                    <Link to={subMenuItem.path} className={subMenuLink}>
                      {subMenuItem.label}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </CSSTransition>
          {open && (
            <div
              role={'button'}
              tabIndex={-1}
              className={subMenuOverlay}
              onClick={closeSubmenu}
              onKeyDown={closeSubmenu}
              {...props}
            >
              {' '}
            </div>
          )}
        </>
      )
    }

    return (
      <ul className={classnames(menuCss, className)} {...props}>
        {items.map((item) => {
          const { label, path, id, subMenu } = item
          return (
            <li key={id} className={menuItem}>
              {subMenu.length > 0 ? (
                <SubMenu key={id} {...item} />
              ) : (
                <Link to={path} className={menuLink}>
                  {label}
                </Link>
              )}
            </li>
          )
        })}
      </ul>
    )
  }

  const MobileMenu = ({ items, className, ...props }) => {
    const MobileSubmenu = ({
      label,
      path,
      subMenu,
      parentId,
      className,
      ...props
    }) => {
      const [isOpen, setIsOpen] = useState(false)

      const toggleMenu = () => {
        setIsOpen(!isOpen)
      }

      return (
        <>
          <a href={path} onClick={toggleMenu} onKeyPress={toggleMenu}>
            {label} <DropDown />
          </a>
          <ul
            className={classnames(mobSubMenu, isOpen && 'is-open')}
            {...props}
          >
            {subMenu.map((item) => {
              return (
                <li key={item.id}>
                  <Link to={item.path} onClick={closeOffCanvas}>
                    {item.label}
                  </Link>
                </li>
              )
            })}
          </ul>
        </>
      )
    }

    return (
      <ul className={mobMenu} {...props}>
        {items.map((item) => {
          const { label, path, id, subMenu } = item

          return (
            <li key={id}>
              {subMenu.length > 0 ? (
                <MobileSubmenu {...item} />
              ) : (
                <Link to={path} onClick={closeOffCanvas}>
                  {label}
                </Link>
              )}
            </li>
          )
        })}
      </ul>
    )
  }

  const openModal = () => {
    setGlobalState('showGetStart', true)
  }

  return (
    <>
      <header
        className={classnames(main, offset > 25 && 'is-active')}
        {...props}
      >
        <div className={container}>
          <div>
            <Link to={'/'} onClick={closeOffCanvas} className={logo}>
              <Logo />
            </Link>
          </div>
          <Breakpoint showFor={'large'}>
            <nav>
              <Menu items={menu} className={menuCss} />
            </nav>
          </Breakpoint>

          <Breakpoint showFor={'large'}>
            <div>
              <Button secondary hollow white onClick={openModal}>
                Consultation
              </Button>
            </div>
          </Breakpoint>
          <Breakpoint showFor={'medium down'}>
            <button
              className={classnames(menuButton, showOffCanvas && 'is-open')}
              onClick={toggleOffCanvas}
            >
              <span> </span>
              <span> </span>
              <span> </span>
            </button>
          </Breakpoint>
        </div>
      </header>
      <Breakpoint showFor={'medium down'}>
        <div className={classnames(offCanvas, showOffCanvas && 'is-open')}>
          <nav>
            <MobileMenu items={menu} />
          </nav>
          <Button
            hollow
            secondary
            white
            className={offCanvasButton}
            onClick={openModal}
          >
            Get started
          </Button>
        </div>
      </Breakpoint>
    </>
  )
}

export default Header
