// extracted by mini-css-extract-plugin
export var container = "Footer-module--container--cIk5r";
export var containerBg = "Footer-module--containerBg--iEEsu";
export var copyrights = "Footer-module--copyrights--qTY6s";
export var info = "Footer-module--info--+xCdj";
export var infoDesc = "Footer-module--infoDesc--0sU17";
export var infoEmail = "Footer-module--infoEmail--FqoQy";
export var lineLink = "Footer-module--line-link--rcKPw";
export var lineLink_black = "Footer-module--line-link_black--r204v";
export var lineLink_grey = "Footer-module--line-link_grey--20ISW";
export var lineLink_primary = "Footer-module--line-link_primary--z862e";
export var lineLink_secondary = "Footer-module--line-link_secondary--pXqfQ";
export var lineLink_white = "Footer-module--line-link_white--34yfq";
export var main = "Footer-module--main--qtoqu";
export var navigation = "Footer-module--navigation--bWTHr";
export var navigationMenu = "Footer-module--navigationMenu--7biYq";
export var navigationTitle = "Footer-module--navigationTitle--PgkqT";