import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

// Components
import { BreakpointTrigger } from '../Breakpoint/Breakpoint'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

import { setGlobalState } from '../../utils/globalState'

// Styles
import 'normalize.css'
import '../../styles/critical.scss'
import '@fontsource/montserrat'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/700.css'

const Layout = ({ location, children }) => {
  setGlobalState('routePath', location)

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPCHA_SITE_KEY}>
      <BreakpointTrigger
        breakpoints={{
          small: 0,
          medium: 640,
          large: 1024,
          xlarge: 1240,
          xxlarge: 1440,
        }}
      />
      <Header />
      <main>{children}</main>
      <Footer />
    </GoogleReCaptchaProvider>
  )
}

export default Layout
