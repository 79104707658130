export default function menuSort(items) {
  const rootItems = items.filter((item) => item.parentId === null && item)
  const childItems = items.filter((item) => item.parentId !== null && item)
  const newItems = rootItems.map((item) => {
    item.subMenu = []
    return item
  })

  childItems.forEach((cItem) => {
    const parent = rootItems.filter((rItem) => rItem.id === cItem.parentId)[0]
    parent.subMenu.push(cItem)
  })

  return newItems
}
