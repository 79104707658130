import React, { useState, useEffect, useCallback } from 'react'
import { useGlobalState, setGlobalState } from '../../utils/globalState'

export const BreakpointTrigger = ({ breakpoints }) => {
  const [breakpoint] = useGlobalState('breakpoint')
  const [allSizes] = useGlobalState('allSizes')
  const [lastSize, setLastSize] = useState(breakpoint)
  const setBreakpoint = (val) => {
    setGlobalState('breakpoint', val)
  }

  const checkSize = useCallback(() => {
    const names = Object.keys(allSizes)
    const lastName = names[names.length - 1]
    const width = window.innerWidth
    let bp = { all: names }

    names.forEach((current, i) => {
      if (width >= allSizes[lastName]) {
        bp['current'] = current
        bp['from'] = allSizes[current]
        bp['to'] = Infinity
      } else if (width >= allSizes[current] && width < allSizes[names[i + 1]]) {
        bp['current'] = current
        bp['from'] = allSizes[current]
        bp['to'] = allSizes[names[i + 1]] - 1
      }
    })

    return bp
  }, [allSizes])

  useEffect(() => {
    setBreakpoint(checkSize()['current'])

    if (breakpoints) {
      setGlobalState('allSizes', breakpoints)
    }
  }, [breakpoints, checkSize])

  useEffect(() => {
    setLastSize(checkSize()['current'])

    const resizeListener = () => {
      const currentSize = checkSize()['current']

      if (currentSize !== lastSize) {
        setBreakpoint(currentSize)
        setLastSize(currentSize)
      }
    }

    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [lastSize, allSizes, breakpoint, checkSize])

  return <></>
}

const Breakpoint = ({ children, showFor }) => {
  const showAttr = showFor.split(' ')
  const dir = showAttr.length > 1 ? showAttr[1] : 'up'
  const bp = showAttr[0]
  const [allSizes] = useGlobalState('allSizes')
  const [size] = useGlobalState('breakpoint')
  const [show, setShow] = useState(false)

  useEffect(() => {
    const sizeNames = Object.keys(allSizes)
    const bpIndex = sizeNames.indexOf(bp)
    const currentIndex = sizeNames.indexOf(size)

    if (dir === 'up') {
      currentIndex >= bpIndex ? setShow(true) : setShow(false)
    } else if (dir === 'down') {
      currentIndex <= bpIndex ? setShow(true) : setShow(false)
    } else if (dir === 'only') {
      currentIndex === bpIndex ? setShow(true) : setShow(false)
    }
  }, [size, allSizes, bp, dir])

  return <>{show && children}</>
}

export default Breakpoint
