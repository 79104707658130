// extracted by mini-css-extract-plugin
export var closeButton = "PopUp-module--closeButton--iR4pX";
export var content = "PopUp-module--content--Iu5Oo";
export var header = "PopUp-module--header--Cwjtk";
export var lineLink = "PopUp-module--line-link--ZpO0k";
export var lineLink_black = "PopUp-module--line-link_black--f+kex";
export var lineLink_grey = "PopUp-module--line-link_grey--mR5zh";
export var lineLink_primary = "PopUp-module--line-link_primary--ppSOO";
export var lineLink_secondary = "PopUp-module--line-link_secondary--PIMFQ";
export var lineLink_white = "PopUp-module--line-link_white--OA3jH";
export var overlay = "PopUp-module--overlay--Ig1zW";
export var portal = "PopUp-module--portal--bx+bD";